<!--系统设置-参数配置-->
<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter'>
    <el-form-item>
      <el-input @change="hasChange" size='mini' v-model.trim='filter.name' placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
    </el-form-item>
  </el-form>
  </el-col>
   <!-- 列表 -->
   <el-col :span='24'>
    <el-table :data='datalist.content' size='mini' border stripe v-loading='loading' style='width: 100%'>
      <el-table-column type='index' align='center' label="#" width='50'></el-table-column>
      <el-table-column prop='Code' header-align='center' width="240" label='编码' align='center'>
      </el-table-column>
      <el-table-column prop='Name' header-align='center' label='名称' align='center'>
      </el-table-column>
      <el-table-column prop='Typ' header-align='center' width="120" label='类型' align='center'>
          <template slot-scope='scope'>
            {{ scope.row.Typ===1?'多项':'单值' }}
          </template>
      </el-table-column>
      <el-table-column prop='Val' show-overflow-tooltip header-align='center' label='值域'>
      </el-table-column>
      <el-table-column label='操作' align='center' width='137' >
      <template slot-scope='scope'>
        <i v-if='scope.row.Typ===1' title='查看' @click='handleView(scope.$index, scope.row)' class='opt el-icon-view' />
        <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' />
        <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete' />
      </template>
      </el-table-column>
    </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-pagination small background @size-change='handleSizeChange' @current-change='handleCurrentChange' :current-page.sync='filter.page' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total'>
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handleClose'>
      <template v-if='dlg.type===1'>
      <el-form ref='form' :model='formData' label-width='80px' :rules='formRule' size='mini'>
        <el-form-item prop='Code' label='编码' >
          <el-input @change="hasChange" v-model.trim='formData.Code' :disabled="this.dlg.title==='编辑'" />
        </el-form-item>
        <el-form-item prop='Name' label='名称' >
          <el-input @change="hasChange" v-model.trim='formData.Name' />
        </el-form-item>
        <el-form-item prop='Val' label='值域' >
          <el-input @change="hasChange" v-model.trim='formData.Val' type="textarea" :autosize="{ minRows: 2, maxRows: 4}" />
        </el-form-item>
        <el-form-item prop='Typ' label='类型' >
          <el-radio v-model.trim="formData.Typ" @change="hasChange" :label="1" >多项</el-radio>
          <el-radio v-model.trim="formData.Typ" @change="hasChange" :label="0" >单值</el-radio>
        </el-form-item>
      </el-form>
      <div slot='footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
      </div>
      </template>
      <template v-else>
        <el-button-group style='margin-bottom: 10px'>
          <el-button type='primary' size='mini' icon='fa fa-plus' @click='handleInlineAdd'></el-button>
          <el-button type='primary' size='mini' icon='fa fa-edit' @click='state=!state' :disabled='!state'></el-button>
          <el-button type='primary' size='mini' icon='el-icon-refresh' @click='handleInlineRefresh' :disabled='!referSlice'></el-button>
        </el-button-group>
          <template v-if='state'>
            <el-row class='ls-row' :gutter='5' style="font-weight:bold">
              <el-col :span='4'>索引</el-col>
              <el-col :span='6'>内容</el-col>
              <el-col :span='14'>类目</el-col>
            </el-row>
            <el-row class='ls-row' :gutter='5' type="flex" v-for='(e, i) in referSlice' :key='i'>
              <el-col :span='4'>{{i+1}}</el-col>
              <el-col :span='6'>{{e["V"]}}</el-col>
              <el-col :span='14'>{{e["Kind"]}}</el-col>
            </el-row>
          </template>
          <template v-else>
            <el-alert center show-icon style="margin-bottom: 8px"
              title="若已关联生产数据，请慎用排序和删除功能"
              type="warning">
            </el-alert>
            <el-row class='ls-row' :gutter='5' v-for='(e, i) in referSlice' type="flex" :key='i'>
              <el-col :span='4'>{{i+1}}</el-col>
              <el-col :span='6'><el-input placeholder="内容" size='mini' v-model.trim='referSlice[i].V'/></el-col>
              <el-col :span='14'>
                <el-select size='mini' @change='options.includes($event)?undefined:options.push($event)' v-model="referSlice[i].Kind"  filterable allow-create clearable placeholder="选择类目">
                  <el-option v-for="v in options" :key="v" :label="v" :value="v">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span='4'>
                <i title='上移' class='opt el-icon-caret-top' @click='handleMoveUp(i)' />
                <i title='下移' class='opt el-icon-caret-bottom' @click='handleMoveDown(i)' />
                <i title='删除' class='opt el-icon-delete' @click='handleInlineDel(i)' />
              </el-col>
            </el-row>
          </template>
        <div slot='footer'>
          <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
          <el-button type='primary' :disabled="state" size='mini' @click.native='handleInlineSubmit' >提交</el-button>
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      state: true,
      code: null,
      referSlice: [],
      loading: false,
      datalist: [],
      options: [],
      dlg: {
        visible: false,
        title: ''
      },
      filter: {
        name: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: '内容不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['props'])
  },
  created() {
    this.getData()
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.loading = true
      var para = { StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size }
      para.Param = {}
      if (this.filter.name) {
        para.Param[this.filter.field] = this.filter.name
      }
      this.$post('admin/listRefer', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    getReferMap(code) {
      this.referSlice = []
      this.$post('admin/listReferParam', { code: code }).then(res => {
        // res.forEach(item => {
        //   this.referSlice.push(item['V'])
        // })
        this.referSlice = res
        this.options = []
        var tmap = {}
        for (let i = 0, len = res.length; i < len; i++) {
          if (res[i].Kind !== '' && tmap[res[i].Kind] === undefined) {
            tmap[res[i].Kind] = true
            this.options.push(res[i].Kind)
          }
        }
      }).catch(() => {})
    },
    handleView: function(index, row) {
      this.dlg.title = row.Name
      this.dlg.type = 0
      this.code = row.Code
      this.state = true
      this.getReferMap(row.Code)
      this.dlg.visible = true
    },
    handleInlineAdd() {
      this.state = false
      this.referSlice.push({})
    },
    handleInlineDel(i) {
      // this.$delete(this.referSlice, k)
      this.referSlice.splice(i, 1)
    },
    handleMoveUp(i) {
      if (i !== 0) {
        const tmp = this.referSlice[i - 1]
        this.$set(this.referSlice, i - 1, this.referSlice[i])
        this.$set(this.referSlice, i, tmp)
      }
    },
    handleMoveDown(i) {
      if (i !== this.referSlice.length - 1) {
        const tmp = this.referSlice[i + 1]
        this.$set(this.referSlice, i + 1, this.referSlice[i])
        this.$set(this.referSlice, i, tmp)
      }
    },
    handleInlineRefresh() {
      this.state = true
      this.getReferMap(this.code)
    },
    handleInlineSubmit() {
      this.$confirm('是否确定提交？', '提示', {}).then(() => {
        const para = { code: this.code, data: this.referSlice }
        this.$post('admin/saveReferParam', para).then(() => {
          this.dlg.visible = false
        }).catch(() => {})
      }).catch(() => {})
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.type = 1
      this.dlg.visible = true
    },
    handleEdit: function(index, row) {
      this.dlg.title = '编辑'
      this.dlg.type = 1
      this.dlg.visible = true
      this.index = index
      this.formData = Object.assign({}, row)
    },
    handleDel: function(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const para = { id: row.Id }
        this.$get('admin/delRefer', para).then(() => {
          this.datalist.content.splice(index, 1)
        })
      }).catch(() => {})
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveRefer', para).then(() => {
              this.dlg.visible = false
            })
          }).catch(() => {})
        }
      })
    },
    handleClose() {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.formData = {}
    }
  }
}
</script>
